import React, { useRef, useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import Loader from "../../Loader/Loader";
import { get, upload, httpOnlyConfig } from "../../../utils/DeApi";
import imageCompression from "browser-image-compression";
import heic2any from "heic2any";
import {
  Modal,
  Button,
  Form,
  OverlayTrigger,
  Tooltip,
  Offcanvas,
  Alert,
  Row,
  Col,
} from "react-bootstrap";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import PropertyDelete from "./PropertyDelete/PropertyDelete";
import PropertyUpdate from "./PropertyUpdate/PropertyUpdate";
import useCheckMobileScreen from "../../../hooks/useCheckMobileScreen";

const AssessmentProperties = () => {
  const subscribedPromises = useRef([]);
  const isMobileScreen = useCheckMobileScreen();

  const [properties, setProperties] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [show, setShow] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleClose = () => {
    setShow(false);
  };
  const toggleShow = () => setShow((s) => !s);
  const handleAddModalClose = () => {
    setShowAddModal(false);
    setSuccess(false);
  };
  const handleAddModalShow = () => {
    setShowAddModal(true);
    setError();
  };

  useEffect(() => {
    fetchProperties();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => promise.cancel());
    };
  }, [show]);

  const fetchProperties = () => {
    setIsLoading(true);
    setError();

    const propertyPromise = get(`properties`);

    propertyPromise.promise
      .then((response) => {
        const sortedProperties = response.data.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        setProperties(sortedProperties);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });

    subscribedPromises.current.push(propertyPromise);
  };

  const handleAddProperty = async (values, { setSubmitting, resetForm }) => {
    setError();
    setIsLoading(true);

    let propertyData = {
      name: values.name,
      description: values.description,
      ...httpOnlyConfig,
    };

    try {
      let fileToBeUploaded = values.image;

      if (
        values.image.type.startsWith("image/") &&
        values.image.type.split("/")[1] !== "heic"
      ) {
        fileToBeUploaded = await imageCompression(values.image, {
          maxSizeMB: 0.5,
          maxWidthOrHeight: 1200,
          useWebWorker: true,
        });
        propertyData.image = new File(
          [await fileToBeUploaded],
          values.image.name,
          {
            type: "image/jpeg",
            lastModified: values.image.lastModified,
            size: fileToBeUploaded.size,
          }
        );
      }

      if (values.image.type.split("/")[1] === "heic") {
        const blob = new Blob([values.image], {
          type: "image/jpeg",
          size: values.image.size,
        });

        const url = URL.createObjectURL(blob);

        const blobUrl = await fetch(url);

        const resBlob = await blobUrl.blob();

        const heicJpg = await heic2any({
          blob: await resBlob,
          toType: "image/jpeg",
          quality: 100,
        });

        const conversionResult = await heicJpg;

        fileToBeUploaded = await imageCompression(conversionResult, {
          maxSizeMB: 0.5,
          maxWidthOrHeight: 1200,
          useWebWorker: true,
        });

        propertyData.image = new File(
          [await fileToBeUploaded],
          values.image.name.replace(/\.heic$/, ".jpeg"),
          {
            type: "image/jpeg",
            lastModified: values.image.lastModified,
            size: conversionResult.size,
          }
        );
      }
    } catch (error) {
      console.log(error);
    }

    const createPropertyPromise = upload(`/properties`, propertyData);

    createPropertyPromise.promise
      .then((response) => {
        resetForm();
        setSuccess(`Property "${values.name}" added successfully.`);
        setTimeout(() => {
          setShowAddModal(false);
          setSuccess(false);
          fetchProperties();
        }, 3000);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
        }
      })
      .finally(() => {
        setSubmitting(false);
        setIsLoading(false);
      });

    subscribedPromises.current.push(createPropertyPromise);
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "Too Short")
      .notOneOf(
        properties.map((item) => item.name),
        "A property with the same name already exists."
      )
      .required("Property Name is Required"),
    description: Yup.string().min(2, "Too Short"),
    image: Yup.mixed()
      .required("Image is required")
      .test(
        "fileType",
        "Unsupported file format. Please upload a JPEG or PNG image.",
        (value) => {
          return (
            value &&
            ["image/jpeg", "image/png", "image/heic"].includes(value.type)
          );
        }
      ),
  });

  return (
    <>
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id="button-tooltip-2">View Properties</Tooltip>}
      >
        <Button
          size="sm"
          variant="outline-primary"
          className={`border-secondary border-opacity-10 ${
            isMobileScreen ? "mx-1 my-2" : "me-2"
          }`}
          onClick={toggleShow}
        >
          <span className="material-symbols-outlined">domain</span>
        </Button>
      </OverlayTrigger>

      <Offcanvas
        show={show}
        onHide={handleClose}
        backdrop={true}
        placement="end"
        style={{ width: `${isMobileScreen ? "100%" : "40%"}` }}
      >
        <Offcanvas.Header
          closeButton
          className="d-flex justify-content-between align-items-center"
        >
          <Offcanvas.Title>Assessment Properties</Offcanvas.Title>
          <div className="d-flex align-items-center ms-auto">
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="button-tooltip-2">Add New Property</Tooltip>
              }
            >
              <Button
                size="sm"
                variant="outline-primary"
                className="border-secondary border-opacity-10 ms-2 me-4"
                onClick={handleAddModalShow}
              >
                <span className="material-symbols-outlined">add</span>
              </Button>
            </OverlayTrigger>
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body className="px-4">
          {isLoading && <Loader />}
          {error && <ErrorHandler error={error} />}
          {!properties && <span />}
          {properties &&
            !!properties.length &&
            properties.map((property, key) => (
              <Row className="py-3 align-baseline" key={key}>
                <Col md={8} xs={12}>
                  <div>
                    <h5 className="text-primary">{property.name}</h5>
                    <p className="text-muted">{property.description}</p>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="text-md-end mt-2">
                    <PropertyUpdate
                      property={property}
                      propertyUpdated={fetchProperties}
                    />{" "}
                    <PropertyDelete
                      property={property}
                      propertyDeleted={fetchProperties}
                    />
                  </div>
                </Col>
              </Row>
            ))}
        </Offcanvas.Body>
      </Offcanvas>

      <Modal show={showAddModal} onHide={handleAddModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Property</Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={{ name: "", description: "", image: null }}
          validationSchema={validationSchema}
          onSubmit={handleAddProperty}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldValue,
            isSubmitting,
            errors,
            touched,
            isValid,
            values,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Body>
                <Form.Group controlId="name" className="mb-4">
                  <Form.Label>Property Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.name && !!errors.name}
                    isValid={touched.name && !errors.name && values.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="description" className="mt-2">
                  <Form.Label>Property Description</Form.Label>
                  <Form.Control
                    type="text"
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={
                      touched.description &&
                      !errors.description &&
                      values.description
                    }
                    isInvalid={touched.description && !values.description}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.description}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="image" className="mt-2">
                  <Form.Label>Property Image</Form.Label>
                  <Form.Control
                    type="file"
                    name="image"
                    onChange={(event) => {
                      setFieldValue("image", event.currentTarget.files[0]);
                    }}
                    onBlur={handleBlur}
                    isValid={touched.image && !errors.image && values.image}
                    isInvalid={touched.image && errors.image}
                    accept=".png,.jpg,.jpeg,.heic"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.image}
                  </Form.Control.Feedback>
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size="sm"
                  variant="secondary"
                  onClick={handleAddModalClose}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  size="sm"
                  disabled={!isValid || !values.image || isLoading}
                >
                  Submit
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>

        {error && <ErrorHandler error={error} />}
        {isLoading && <Loader />}
        {success && (
          <Alert variant="success" className="mb-3 position-relative">
            {success}
          </Alert>
        )}
      </Modal>
    </>
  );
};

export default AssessmentProperties;
